import { default as index0zRf5vuBHRMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/custom/index.vue?macro=true";
import { default as packages5yjTEHk4fmMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/custom/packages.vue?macro=true";
import { default as partsqQrjav76JMMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/custom/parts.vue?macro=true";
import { default as custom6zBKfwgWajMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/custom.vue?macro=true";
import { default as indexLMEotlGuKYMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/index.vue?macro=true";
import { default as ratesOEBt3WyXIDMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/rates.vue?macro=true";
import { default as subscriptionOaCub8tIkMMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/subscription.vue?macro=true";
import { default as accountj1TeN9MQJYMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account.vue?macro=true";
import { default as _91id_93HpylfVMnDBMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexxr6plj0qKeMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93p0oiKlRalmMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93Wt2z9iAQhiMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/activite/commandes/[id].vue?macro=true";
import { default as indexsL9iXJSoznMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculesHKYVGHl4YpMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_93Sz78IrcxIDMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/boutique/[title]-[id].vue?macro=true";
import { default as indexWMGrpxLo7dMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/boutique/index.vue?macro=true";
import { default as productsgrlh67UoapMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/boutique/products.vue?macro=true";
import { default as indexRhAlNDga0vMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/index.vue?macro=true";
import { default as configurationLHfXSiFGFRMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as indexTAU7165BXsMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/maintenance/index.vue?macro=true";
import { default as planlo28mIau73Meta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/maintenance/plan.vue?macro=true";
import { default as worksmxeMqLmzSuMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenancer2N6GIdTcTMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/maintenance.vue?macro=true";
import { default as manualsjOmIVutHbXMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/manuals.vue?macro=true";
import { default as timesK7aPI0RcztMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/times.vue?macro=true";
import { default as catalogYQ9ziJeuL7Meta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog.vue?macro=true";
import { default as commandeuNWZqjGnqeMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/commande.vue?macro=true";
import { default as _91tab_93142L3jMypOMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/devis/[tab].vue?macro=true";
import { default as _91id_93elbTOQSRIQMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93exLUXojQqjMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdfOAoz02KLU7Meta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/imprimer-pdf.vue?macro=true";
import { default as indexuIEIjzm15SMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/index.vue?macro=true";
import { default as loginbOecOlMs7FMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/login.vue?macro=true";
import { default as panier7uX3pjES4MMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/panier.vue?macro=true";
import { default as indexuDeU5ifLVAMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/pneumatique/index.vue?macro=true";
import { default as productsnYBnnUDHMnMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/pneumatique/products.vue?macro=true";
import { default as pneumatiqueYbssdCphSnMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/pneumatique.vue?macro=true";
import { default as maintenanceAkVUKCdAF7Meta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/print/maintenance.vue?macro=true";
import { default as productsfSsMjkdVzsMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/products.vue?macro=true";
import { default as signup9WNHgNesHhMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/signup.vue?macro=true";
import { default as tableau_45de_45bord3HtjTDsZwoMeta } from "/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountj1TeN9MQJYMeta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account.vue"),
    children: [
  {
    name: custom6zBKfwgWajMeta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/activite/vehicules.vue")
  },
  {
    name: "boutique-title-id",
    path: "/boutique/:title()-:id()",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/boutique/[title]-[id].vue")
  },
  {
    name: "boutique",
    path: "/boutique",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/boutique/index.vue")
  },
  {
    name: "boutique-products",
    path: "/boutique/products",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/boutique/products.vue")
  },
  {
    name: catalogYQ9ziJeuL7Meta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/index.vue")
  },
  {
    name: maintenancer2N6GIdTcTMeta?.name,
    path: "maintenance",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdfOAoz02KLU7Meta || {},
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginbOecOlMs7FMeta || {},
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/panier.vue")
  },
  {
    name: pneumatiqueYbssdCphSnMeta?.name,
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenanceAkVUKCdAF7Meta || {},
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signup9WNHgNesHhMeta || {},
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/capdevilleauto.fr/dev-app.capdevilleauto.fr/pages/tableau-de-bord.vue")
  }
]